import jwtService from "./common/jwt.service";
import ApiService from './common/api.service';
import toastr from "toastr";



let token = document.head.querySelector('meta[name="csrf-token"]');

var alfredOptions = {
	axiosBaseURL: '../../../../../web/',
	// csrftoken: token.content,
	awsAppSyncApiKey: 'da2-c74fpa6es5hnbek7w2zjck34ba'
};


export async function getAutorizationGoogle() {

	/**
	 * 
	 * Comentado pra nao acontecer quando logar quero deixar esa config apenas no botao, se um dia for usar como autoload só descomentar.
	 * 
	 */

	// let user = JSON.parse(jwtService.getUser());
	// if (user.is_authorized_google_calendar === null) {

	// 	var queryString = window.location.search;
	// 	var params = new URLSearchParams(queryString);

	// 	var chaveCode = params.get('code')
	// 	var chaveError = params.get('error');



	// 	if (chaveCode === null && chaveError === null) {

	// 		window.location.href = 'https://api.alfred.test/api/google/login';

	// 	} else {

	// 		let objeto = null;
	// 		if (chaveCode !== null) {
	// 			objeto = { code: chaveCode }
	// 		}

	// 		if (chaveError !== null) {
	// 			objeto = { error: chaveError }
	// 		}
	// 		console.log(objeto)
	// 		await ApiService.post('https://api.alfred.test/api/google/autorizaAcesso', objeto)
	// 			.then((response) => {
	// 				console.log(response)
	// 				switch (response.data.status) {
	// 					case 'fail':
	// 						toastr.error(response.data.message);
	// 						user.is_authorized_google_calendar=null
	// 						jwtService.saveUser(user);
	// 						break;
	// 					case 'success':
	// 						toastr.success(response.data.message);
	// 						user.is_authorized_google_calendar=1
	// 						jwtService.saveUser(user);

	// 						break;

	// 					default:
	// 						break;
	// 				}
	// 			})
	// 			.then(() => {
	// 			})
	// 			.catch(function (error) {
	// 				console.log(error);
	// 			});
	// }






	// }
}

export function getAutorizationGoogleRedirect() {
	// window.location.href = 'https://api.alfred.test/api/google/login';
	window.location.href = 'https://api.alfred.com.br/api/google/login';
}
export async function verificaSeTemRespostaGoogle() {
	var queryString = window.location.search;
	var params = new URLSearchParams(queryString);

	var chaveCode = params.get('code');
	var chaveError = params.get('error');

	if (chaveCode !== null || chaveError !== null) {
		let objeto = null;
		if (chaveCode !== null) {
			objeto = { code: chaveCode };
		}

		if (chaveError !== null) {
			objeto = { error: chaveError };
		}
		// await ApiService.post('https://api.alfred.test/api/google/autorizaAcesso', objeto)
		await ApiService.post('https://api.alfred.com.br/api/google/autorizaAcesso', objeto)
			.then((response) => {
				console.log(response);
				switch (response.data.status) {
					case 'fail':
						toastr.error(response.data.message);
						user.is_authorized_google_calendar = null;
						jwtService.saveUser(user);
						break;
					case 'success':
						toastr.success(response.data.message);
						user.is_authorized_google_calendar = 1;
						jwtService.saveUser(user);

						break;

					default:
						break;
				}
			})
			.then(() => { })
			.catch(function (error) {
				console.log(error);
			});
	}
}

export default alfredOptions;

// DEV
// MIX_API_URL=https://app.arktop.test/api/v1.0/
// MIX_INTERNAL_API_TOKEN=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijk4ZjgxZDFiNjcwZGFiNzNlMzg1YmI1YmM1YzEyMmIyMzMzZGIxOTMyYWVmNjkzMmE2NTYwZDFkMWI0M2VkMTNmY2Q4ZDQzMzljZWRhNDRiIn0.eyJhdWQiOiI3IiwianRpIjoiOThmODFkMWI2NzBkYWI3M2UzODViYjViYzVjMTIyYjIzMzNkYjE5MzJhZWY2OTMyYTY1NjBkMWQxYjQzZWQxM2ZjZDhkNDMzOWNlZGE0NGIiLCJpYXQiOjE1ODYzMjAyMjMsIm5iZiI6MTU4NjMyMDIyMywiZXhwIjoxNjE3ODU2MjIzLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.ozNetdO9mzWu88kXn8atwM61A-XFTq7ciEVEhZRxAMwMyethA2r67a5qX0xo4DozF7GOCivdzfQ0sUBvO7QfhgeIG__FmYeecNBK_mnrfIDFJW-c7bePFXjINv6gCTi3i69LK3lHJ2zHWrLkVCqQ56Dan0aDJ7LzqjTCNELMrfcCj60cXGlOh2oQmlcOUgKm2f_JTMpJZIAE2guqmCGCpgTtNlyuJOLMTjwkPq_kUPcg01RaOImcmWveeACsRTmeQjysBB4esci0q7mOk5L5Bdhv8x_jsinKNTg9zeHrWImKr-rGcof1GjuDWTpb8NK5ew7s1mZOUNtHF7clHO5W2UB9GkiZluYDVGFK5uxjUu7OqzQVqa_gCR37-dv97f1wt5lgFuKD2nMOyPwnQMd4JrkWlWUEYpvqZBZ1q6N25U2sD4Qyf6SE9P0wZr-RTay4J1XoviEi-VlDDxBVi7t3GEm_EqmS1veY2ZNAng8x4GIM_aVtPEiuQOyt0et0fq4oodpitjvoQKBqQ2B_BGV2uubbdq9qaIow90B3ArloXTROQBdO55TQrjSXm5Ad1ffwQ6igzHMfrx3F0XLya6yyozsbHLpl-O3R1w1YQ88rntc988bMANIyEy6rKvoRzqq_OsVOdKWfktIuwcVkjMUEb4egvE0Gny2ZoXRXUnIoqJ8

// PROD
// MIX_API_URL=https://app.arktop.com.br/api/v1.0/
// MIX_INTERNAL_API_TOKEN=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImI2YTM0ODk1MGY3MTQ5OWEzY2FiZGIxZWFhNDcyMTYyYzYxOThjOWFhNTY4ZDNjZjhhZTQ0ODU1NjU4Zjc5YzJjMGQ1NjRhMGMyOGMyZTFhIn0.eyJhdWQiOiIzIiwianRpIjoiYjZhMzQ4OTUwZjcxNDk5YTNjYWJkYjFlYWE0NzIxNjJjNjE5OGM5YWE1NjhkM2NmOGFlNDQ4NTU2NThmNzljMmMwZDU2NGEwYzI4YzJlMWEiLCJpYXQiOjE1Njg0MDczNDIsIm5iZiI6MTU2ODQwNzM0MiwiZXhwIjoxNjAwMDI5NzQyLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.nu1NNlDt-o82ITDkN10dt-sDo8Wdoa5Mo2LQCQdcGSzgkcZVEmrf_ugeqyghZJj_748eZt_317K-_epvQGUc4ADlG91UvfbJ_-4WRVifoqxVehuK24Gq4nNsVHXlrrw14eV2PrRRmss6wIu14f8P14rOwUuiM9ai50pUFEOIomUMmMZ0rQ2qzBHMDkKHIg1MFhwufXiPm6Sy-s4poGkwsDf0tvYZkXUzCAD37tiGN1YGDW3kb4McAtCCM4W9Wl1uA6YDxisKYom_up988OHHuhgm0ntxcCU_-PYHXKbdmswP9QiKH6GZ11KJsCOgrcp7_ZKyWuMO0nY4hmPsakFBEshbzm39Zd6-DWsS9S6dOMZghYYYOwP7Bh0GMm_Cfele_RpU-J7js37gcW7RNv0RzJTrPgnEd8bIqxYplhjMlqV2dtWtXDJSZZyN9kvGJCDAIBQ3VYiqxGJ6vJwiGQSWpiJ1D01w-o0JLwDyCzCJ87QnVj6Cy4KkEtZqT44HPVo_uK9AktT7SNUj3Az9t48b0PtsS6GrFwsmbjzBbbWuMW2xRJKz_vYrEjXPpnmTp2xjF65yhlXM4FM4ZEO6cDJHkCZE3baPhYy-n4zj_jE8SUTePxcPaTM4e4fhmQ8QVgv9Ts586IvOr4Zfa2wIpfI_Wb-voiI1Uj00sRi28agsM0k
