import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from './jwt.service';
import router from '../router';

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);

		Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
		// console.log(process.env.VUE_APP_BASE_URL);
		Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		// Vue.axios.defaults.withCredentials = true;

		Vue.axios.interceptors.response.use(
			(response) => {
				if (response.status === 200 || response.status === 201) {
					if (response.data.status === 'fail') {
						// window.location.href = '/testeFelipe';
						// console.log(response);
						router.push({ name: 'cadastroTelefone', params: { response: response } });

						return;
					}
					return Promise.resolve(response);
				} else {
					return Promise.reject(response);
				}
			},
			// function (response) {
			//   return response;
			// },
			(error) => {
				// console.log('oi felipe');
				if (error.response) {
					if (error.response.status) {
						// DEBUGANDO
						// console.log(error.response);
						// return;
						// DEBUGANDO
						switch (error.response.status) {
							case 302:
								JwtService.destroyToken();
								JwtService.destroyUser();
								JwtService.destroyUserPermissions();
								delete axios.defaults.headers.common['Authorization'];
								// router.push({ name: "home" });
								// window.location.href = '/';
							case 400:
								//do something
								break;

							case 401:
								JwtService.destroyToken();
								JwtService.destroyUser();
								JwtService.destroyUserPermissions();
								delete axios.defaults.headers.common['Authorization'];
								// router.push({ name: "home" });
								// window.location.href = '/';
							case 403:
								JwtService.destroyToken();
								JwtService.destroyUser();
								JwtService.destroyUserPermissions();
								delete axios.defaults.headers.common['Authorization'];
								// router.push({ name: "home" });
								// window.location.href = '/';
							// break;
							case 404:
								// alert("page not exist");
								break;
							case 419:
								JwtService.destroyToken();
								JwtService.destroyUser();
								JwtService.destroyUserPermissions();
								delete axios.defaults.headers.common['Authorization'];
								// router.push({ name: "home" });
								// window.location.href = '/';
							// break;
							case 502:
								setTimeout(() => {
									JwtService.destroyToken();
									JwtService.destroyUser();
									JwtService.destroyUserPermissions();
									delete axios.defaults.headers.common['Authorization'];
									// router.push({ name: "home" });
									// window.location.href = '/';
								}, 1000);
						}
						return Promise.reject(error.response);
					}
				}
			}
		);
	},

	setHeader() {
		Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`;
	},
	destroyHeader() {
		delete Vue.axios.defaults.headers.common['Authorization'];
	},
	query(resource, params) {
		return Vue.axios.get(resource, params).catch((error) => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},

	get(resource, slug = '') {
		// console.log("JwtService.getToken: " + JwtService.getToken());
		if (JwtService.getToken() !== null) {
			this.setHeader();
		} else {
		}
		let params = '';
		if (!_.isNaN(slug)) {
			params = '?' + slug;
		}
		if (_.isObject(slug)) {
			params =
				'?' +
				_.keys(slug)
					.filter((key) => slug[key] != null && slug[key] !== 'null')
					.map((key) => key + '=' + slug[key])
					.join('&');
		}
		return Vue.axios.get(`${resource}${params}`).catch((error) => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},

	post(resource, params) {
		if (JwtService.getToken() !== null) {
			this.setHeader();
		}
		return Vue.axios.post(`${resource}`, params);
	},

	postfile(resource, params) {
		if (JwtService.getToken() !== null) {
			this.setHeader();
		}
		return Vue.axios.post(`${resource}`, params, {
			headers: { 'Content-Type': 'multipart/form-data' }
		});
	},

	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params);
	},

	put(resource, params) {
		return Vue.axios.put(`${resource}`, params);
	},

	delete(resource) {
		return Vue.axios.delete(resource).catch((error) => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	}
};

export default ApiService;
